import ApiService from "@/common/api.service";

export default {
  createClass(data) {
    return ApiService.post("/teaching-classes", data);
  },
  getClassDetail(id) {
    return ApiService.query(`/teaching-classes/${id}`);
  },
  updateClass(id, data) {
    return ApiService.patch(`/teaching-classes/${id}`, data);
  },
  removeStudent(id, data) {
    return ApiService.post(`/teaching-classes/${id}/remove-user`, data);
  },
  addUsers(id, data){
    return ApiService.post(`/teaching-classes/${id}/add-users`, data);
  },
  leaveClass(id) {
    return ApiService.post(`/teaching-classes/${id}/leave`);
  },
  getIvyWayUsers(data) {
    return ApiService.query("/ivy-way/users", data);
  }
};
